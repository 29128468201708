//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

.wp-admin {
  ul.category-tabs  {
    display: none;
  }

  .tabs-panel {
    max-height: none !important;
    margin: 0 !important;
    padding: 0 0 0 1px !important;
    border: 0 !important;

    .categorychecklist {
      margin: 2px 0 0 !important;
    }
  }
}
